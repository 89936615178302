import log from "loglevel";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useBranding } from "../branding";
import {
  requestNotificationPermission,
  resetPermissionRequested,
  selectNotification,
} from "../features/notificationSlice";
import {
  selectSessionNotification,
  selectSessionState,
} from "../features/sessionSlice";
import { useAppDispatch, useAppSelector, useNotificationToast } from "../hooks";
import { SESSION_STATE } from "../session/session-state";
import { SessionType } from "../session/types";
import { routes } from "../utils/routes";

export function SessionNotifications() {
  const { data: branding } = useBranding();
  const toast = useNotificationToast();
  const { t } = useTranslation();
  const sessionNotification = useAppSelector(selectSessionNotification);
  // react-router sucks and without using a ref the navigate function will change on every navigation, triggering a rerender and any effect that depends on navigate
  // see https://github.com/remix-run/react-router/issues/7634
  const navigate = useRef(useNavigate());

  const { permission: notificationPermission, permissionRequested } =
    useAppSelector(selectNotification);
  const dispatch = useAppDispatch();
  const currentSession = useAppSelector(selectSessionState);

  // Request notifications as soon as a session is started
  useEffect(() => {
    if (currentSession.state >= SESSION_STATE.REQUESTED) {
      return;
    }
    if (
      notificationPermission !== "denied" &&
      notificationPermission !== "granted"
    ) {
      dispatch(requestNotificationPermission());
    }
  }, [currentSession.state, dispatch, notificationPermission]);

  // Show a toast confirming that browser / html5 notifications are now enabled if they were requested
  useEffect(() => {
    if (!permissionRequested) {
      return;
    }

    dispatch(resetPermissionRequested());

    if (notificationPermission === "granted") {
      toast({
        title: t("session.notification_enabled_title"),
        description: t("session.notification_enabled_text"),
        status: "success",
        duration: 9000,
      });
    } else if (notificationPermission === "denied") {
      toast({
        title: t("session.notification_denied_title"),
        description: t("session.notification_denied_text"),
        status: "warning",
        duration: 9000,
      });
    }
  }, [notificationPermission, permissionRequested, toast, t, dispatch]);

  // Sideeffect to send html5 notification once session is ready
  useEffect(() => {
    // don't notify for locally running sessions
    if (
      currentSession.type === SessionType.Unknown ||
      [
        SessionType.LocallyRenderedStandalone,
        SessionType.LocallyRenderedWindows,
      ].includes(currentSession.type)
    ) {
      return;
    }

    if (
      currentSession.state !== SESSION_STATE.READY ||
      notificationPermission !== "granted" ||
      document.visibilityState === "visible"
    ) {
      return;
    }
    // notify about the session being ready
    const notification = new Notification(t("session.ready"), {
      body: t("session.notification"),
      icon: branding?.product_icon || undefined,
    });
    notification.onclick = () => {
      window.focus();
      notification.close();
    };
    const listener = () => {
      if (document.visibilityState === "visible") {
        // The tab has become visible so clear the now-stale Notification.
        notification.close();
      }
    };
    document.addEventListener("visibilitychange", listener);

    return () => document.removeEventListener("visibilitychange", listener);
  }, [
    branding?.product_icon,
    currentSession.state,
    t,
    notificationPermission,
    currentSession.type,
  ]);

  // Sideeffect to send html5 notification if a session ran too long
  useEffect(() => {
    if (!sessionNotification) return;
    if (sessionNotification.notificationType === undefined) return;

    let notificationContent = undefined;

    switch (sessionNotification.notificationType) {
      case "MaxSoftSessionRunTime":
        notificationContent = t("notifications.MaxSoftSessionRunTime");
        break;
      case "MaxSessionRunTime":
        notificationContent = t("notifications.MaxSessionRunTime");
        break;
      case "CannotDetermineBestRegionForRendering":
        notificationContent = t(
          "notifications.CannotDetermineBestRegionForRendering",
          { region: sessionNotification.params?.FallbackRegionDisplayName },
        );
        break;
      case "LargeDistanceToRenderServer":
        notificationContent = t("notifications.LargeDistanceToRenderServer", {
          region: sessionNotification.params?.RegionDisplayName,
          km: parseInt(sessionNotification.params?.DistanceM ?? "") / 1000,
        });
        break;
      case "HighLatency":
        notificationContent = t("notifications.HighLatency", {
          region: sessionNotification.params?.RegionDisplayName,
          latency: parseInt(sessionNotification.params?.LatencyMs ?? ""),
        });
        break;
      case "FallbackToBackupRegion":
        notificationContent = t("notifications.FallbackToBackupRegion", {
          region: sessionNotification.params?.FallbackRegionDisplayName,
        });
        break;
      default:
        notificationContent = undefined;
    }

    if (notificationContent === undefined) {
      log.error("Unhandled notification", sessionNotification);
      return;
    }

    const status =
      sessionNotification.level === "Information"
        ? "info"
        : sessionNotification.level.toLowerCase();

    toast({
      title: t("notifications.title"),
      description: notificationContent,
      status: status as "info" | "warning" | "error" | "success",
    });

    if (
      sessionNotification.alert === false ||
      notificationPermission !== "granted" ||
      document.visibilityState === "visible"
    ) {
      return;
    }

    const notification = new Notification(t("notifications.title"), {
      body: notificationContent,
      icon: branding?.product_icon || undefined,
    });
    notification.onclick = () => {
      window.focus();
      navigate.current(routes.session);
      notification.close();
    };
    const listener = () => {
      if (document.visibilityState === "visible") {
        // The tab has become visible so clear the now-stale Notification.
        notification.close();
      }
    };
    document.addEventListener("visibilitychange", listener);

    return () => document.removeEventListener("visibilitychange", listener);
  }, [
    branding?.product_icon,
    sessionNotification,
    t,
    toast,
    notificationPermission,
  ]);

  return null;
}
